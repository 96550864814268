<template>
  <div class="rules" style="display: flex;justify-content: center;width: 100%;text-align: left;">
    <div style="width: 95%">
      <h1>Vllshop关于商品、订单通知及申请FAQ</h1>
      <hr>
      <p>
        Q1：为什么会出现如下原因：<br>
        1、因避免Vllshop平台规则导致的，具体可参考【规则中心】→【规则】 ：<br>
        <a href="https://www.vllshop.com/RuleHome?activeKey=2">https://www.vllshop.com/RuleHome?activeKey=2</a><br>
        <br>
        特殊品类：
        <br>
        <a href="https://www.vllshop.com/RuleHome?activeKey=1&current=1000">https://www.vllshop.com/RuleHome?activeKey=1&amp;current=1000</a>
        <br>
        <br>
        禁售：<br>
        <a href="https://www.vllshop.com/RuleHome?activeKey=2&current=1000&ruleId=2002">https://www.vllshop.com/RuleHome?activeKey=2&amp;current=1000&ruleId=2002</a><br>
        <br>
        违规：<br>
        <a href="https://www.vllshop.com/RuleHome?activeKey=2&current=1000&ruleId=2003">https://www.vllshop.com/RuleHome?activeKey=2&amp;current=1000&ruleId=2003</a><br>
        <br>
        交易规则：<br>
        <a href="https://www.vllshop.com/RuleHome?activeKey=2&current=1000&ruleId=2001">https://www.vllshop.com/RuleHome?activeKey=2&amp;current=1000&ruleId=2001</a><br>
        <br>
        因过多引发投诉 不能及时通知Q2：能否提前通知，一定要进行改进？<br>
        A：在通知前，均会提前24小时进行。请您关注店铺后台的【安全中心】消息，以及店铺的家门口通知栏时，您可以在此24小时内填写您的通知管理。邮件中的具体内容没有进行，进行相应的改进或提交则备案。若24小时内没有进行改进或备案，则进行处理。超过<br>
        ：注明禁售类信息，提前2小时进行提醒，若超过2小时或备案，则进行通知<br>
        <br>
        Q3：可以通过什么提交材料？<br>
        A：在收到邮件提醒后，通过【店铺管理后台】→【安全中心】提交相关资质材料。也可以通过邮箱：<em>jingdan@wonwhale .com</em>提交相关的资质材料<br>
        <br>
        Q4：被提交后需要提供什么资料进行申请？或者进行备案需要提交什么材料<br>
        申诉/备案材料会根据具体情况有所区别，具体可参考附件《Vllshop、店铺申诉要求》
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopFAQ'
}
</script>

<style scoped>
a {
  color: #001BFF;
}
</style>
